.Dashboard {
  width: 100%;
  border: 3px solid $color-border;
  padding: 20px;
  margin: 20px 0;
  @media($mq-from-tablet) {
    margin: 0 0 20px 0;
    padding: 30px;
  }

  &[aria-hidden=true] {
    display: none;
  }

  .PrimaryButton {
    margin: 10px 0 0 0;
  }
}

.Dashboard--frame { // variant used for login details frame
  position: relative;
  margin-top: 50px;
  .Dashboard-title {
    font-size: 0.6rem;
    position: absolute;
    text-align: center;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $color-white;

    @media($mq-from-tablet) {
      width: auto;
      font-size: 0.9rem;
    }

    &::before {
      content: '';
      position: absolute;
      left: -15px;
      width: 15px;
      height: 15px;
      background-color: $color-white;
    }
    &::after {
      content: '';
      position: absolute;
      right: -15px;
      width: 15px;
      height: 15px;
      background-color: $color-white;
    }
  }

}

.Dashboard-title {
  @include thermor-title4c;
  * + & {
    margin-top: 40px;
  }
}

.Dashboard-paragraph {
  color: $color-black;
  font-size: 0.9rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}

.Dashboard-infoLine {
  color: $color-black;
  font-size: 0.8rem;
  line-height: 1.69;;
}

.Dashboard-link {
  display: block;
  font-size: 0.8rem;
  line-height: 2.13;
  color: $color-black;
}

.Dashboard-label {
  font-weight: 700;
}
