/*! IconLink component */
// Uses link basis and allows a svg to be added before label

.IconLink {
  display: inline-block;
  border: none;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.6rem;
  line-height: 1.25;
  font-weight: 400;
  color: $color-charcoal;
  background-color: transparent;
  cursor: pointer;
  transition: color 50ms;

  &:hover,
  &.focus-visible {
    color: $color-orange;
  }

  svg {
    margin-right: 8px;
  }

  @media($mq-desktop) {
    padding: 20px 0;
  }
}
