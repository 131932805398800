/*! SecondaryButton */

.SecondaryButton {
    position: relative;
    display: inline-block;
    border-radius: 0;
    border: none;
    margin: 10px 0;
    text-align: center;
    text-decoration: none;
    font-size: 0.8rem;
    line-height: 1;
    font-weight: 900;
    color: $color-black;
    background-color: transparent;
    cursor: pointer;
    transition: color 100ms;

    @media ($mq-from-tablet) {
        font-size: 0.9rem;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -7px;
        left: 0;
        width: 100%;
        border-bottom: 2px solid currentColor;
    }

    &:hover,
    &.focus-visible {
        color: $color-orange-darker;
    }

    &.focus-visible {
        outline: solid 2px currentColor;
        outline-offset: -2px;
    }

    &[disabled] {
        color: $color-black-a40;
    }
}
