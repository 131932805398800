.Container--centered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.Container-flex {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: auto;
  @media ($mq-from-tablet) {
    flex-direction: row;
    justify-content: start;
  }
}

.Container-flex--wrap {
  flex-wrap: wrap;
  flex-direction: initial;
  @media ($mq-desktop) {
    flex-direction: initial;
  }

  &:not(:first-child) {
    margin-top: 30px;
  }
}

.Container-flex--stretch  {
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: space-around;
}

.Container-col {
  display: flex;
  flex-direction: column;
}

.Container-col--1-3 {
  @media($mq-desktop) {
    width: 40%;
  }
  width: 100%;
}

.Container-col--1-2 {
  @media($mq-desktop) {
    width: calc(50% - 16px);
    margin-right: 16px;
  }
  width: 100%;


  & + * {
    margin-top: 20px;
    @media($mq-desktop) {
      width: calc(50% - 16px);
      margin-left: 16px;
      margin-bottom: 0;
    }
  }
}

.Container-col--2-3 {
  @media($mq-desktop) {
    width: 60%;
  }
  width: 100%;
}

.Container-col--3-4 {
  @media($mq-from-tablet) {
    width: calc(75% - 20px);
    margin-right: 20px;
  }
  width: 100%;

  & + * {
    margin-top: 20px;
    @media($mq-from-tablet) {
      margin-top: 0;
    }
  }
}

.Container-col--1-4 {
  @media($mq-from-tablet) {
    width: 25%;
  }
  width: 100%;
}


.Container-L-4 {
  flex-grow: 1;
  width: 100%;
  @media($mq-desktop) {
    width: 18%;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  @media($mq-tablet) {
    width: 45%;
    &:nth-child(odd) {
      margin-right: 40px;
    }
  }
}

.Container-flex--centered {
  justify-content: center;
}