/*! Footer Component */

.Footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: $color-white;
  background-color: $color-dark;

  @media ($mq-from-tablet) {
    font-size: 0.8rem;
  }

  @media print {
    display: none;
  }
}
.Footer--fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.Footer-wrapper {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin: auto;
  padding: 24px 0;

  @media($mq-desktop) {
    flex-direction: row;
    width: 100%;
  }
}

.Footer-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  @media($mq-desktop) {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 0;
  }
}

.Footer-sidelink {
  color: $color-white;
  text-decoration: none;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.2;
  @media($mq-from-tablet) {
    font-size: 0.6rem;
  }
}

.Footer-sideitem {
  &:not(:last-child) {
    margin-bottom: 18px;
  }
  @media($mq-desktop) {
    margin-right: 45px;
    margin-bottom: 0;
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}
