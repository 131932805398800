/******
  Structure
******/

.Form-wrapper {
  display: flex;
  flex-direction: column;
}

.Form-section {
    margin: 20px 0;
}

.Form-inlineLabel {
    display: flex;

}

.Form-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 15px 0;

    @media($mq-desktop) {
        flex-direction: row;
        align-items: center;
        .Form-group + .Form-group {
            margin-left: 5px;
        }
    }

    .Form-label {
        flex: 0 0 15%;
        margin: 0;
        font-weight: 400;
        color: $color-text-low-contrast;

        & + .Form-input {
            flex: 0 0 85%;
            width: 100%;
        }
    }


    & + & {
        margin: 0;
    }

}

.Form-group {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 20px 0;
    @media($mq-desktop) {
        width: 70%;
    }

    & + .Form-title {
        margin-top: 0;
    }

    &[aria-hidden=true] {
      display: none;
    }
}

.Form-group--full {
    @media($mq-desktop) {
        width: 100%;
    }

}

/******
  Title & Texts
  Light version (default, no wrapper prefix needed (.Form-wrapper--light|dark)
******/

.Form-mainTitle {
    margin-bottom: 40px;
    color: $color-dark-gray;
    font-size: 2.2rem;
    font-weight: 900;
    line-height: 1.14;
    text-transform: uppercase;
}

.Form-mainTitle--light {
  margin-bottom: 8px;
  color: $color-white;
}

.Form-subtitle {
    margin-bottom: 22px;
    color: $color-gray-2;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1;
    text-transform: uppercase;
    @media($mq-desktop) {
        font-size: 1.4rem;
    }
}

.Form-title {
    font-weight: 700;
    font-size: 1rem;
    color: #000;
    margin-top: 20px;
    margin-bottom: 10px;
    &:first-child {
        margin-top: 0;
    }
}

.Form-text {
    margin-top: 20px;
}

.Form-listLabels {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    .Form-label {
        flex: 0 0 18%;
        margin-right: 10px;
    }
}

.Form-label {
    width: 100%;
    color: #000;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1.18;
    margin: 0 0 8px 0;
}
.Form-label--light {
    color: $color-charcoal;
}


input + .Form-label {
    padding-top: 2px;
}

.Form-label.required, .Dashboard-title.required {
    &:after {
        content: ' *';
        color: $color-orange;
    }
}

.Form-select {
    border: 2px solid $color-border;
    color: $color-charcoal;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    padding: 10px 50px 10px 12px;
    margin-bottom: 10px;
}

.Form-selectWrapper {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 10px;
        right: 18px;
        border-top: 2px solid $color-charcoal;
        border-left: 2px solid $color-charcoal;
        transform: rotate(-137deg);
        border-radius: 2px;
        width: 16px;
        height: 16px;
        pointer-events: none;
    }
}   // select doesn't accept :after element, tricking this w a wrapper ontop

.Form-selectWrapper--full {
    width: 100%;
}

// Input error
.Form-group--error {
    input {
        color: $color-orange;
        border: 3px solid $color-orange;
    }
    p {
      margin-top: 5px;
      font-size: 13px;
    }
}

.Form-group--valid {
    input, select {
        color: $color-valid;
        border: 3px solid $color-valid;
    }

    p {
        margin-top: 5px;
        color: $color-valid;
        font-size: 13px;
    }
}

.Form-input {
    height: 53px;
    padding: 12px 10px;
    border: 3px solid transparent;
    outline: 2px solid transparent;
    transition: outline 300ms ease-in-out;

    &::placeholder {
        color: rgba($color-dark-gray,0.7);
    }

    &[data-input]:focus + .Popover[data-popover] {
        opacity: 1;
        pointer-events: initial;
    }
}

.Form-input--lighter {
    height: 40px;
    padding: 10px 12px;
    border: 2px solid $color-border;
}

/* Errors */

.Form-errorMessage {
    text-align: left;
    color: $color-orange;
    background-color: rgba($color-orange, 0.1);
    border: 1px solid $color-orange;
    font-size: 14px;
    font-weight: 700;
    padding: 3px 15px;
    margin-top: 8px;
}

.Form-errorMessage--single {
    color: $color-orange;
    a {
      color: $color-orange;
    }
}

/******
  Radios and Checkboxes
******/

// Checkboxes
input[type="checkbox"]:not(:checked),
input[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

input[type="checkbox"]:checked + label:before {
    border: 1px solid transparent;
}


/* Ajout du label */
input[type="checkbox"]:not(:checked) + label,
input[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 29px !important;
    cursor: pointer;
    font-weight: 700 !important;
    margin-bottom: 0;
}
input[type="checkbox"]:checked + label {
    font-weight: 700!important;
}

/* :before sert à créer la case à cocher */
input[type="checkbox"]:not(:checked) + label:before,
input[type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    z-index: 10;
    left:0; top: 0;
    width: 22px;
    height: 22px; /* dim. de la case */
    border: 1px solid $color-charcoal;
    background: transparent;
}

/* Aspect général de la checkbox*/
input[type="checkbox"]:not(:checked) + label:after,
input[type="checkbox"]:checked + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    color: #fff;
    border-radius: 4px;
    background-color: $color-black;
    transition: background-color 0.2s ease-in-out;
}

/* Aspect si "pas cochée" */
input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0; /* coche invisible */
    transform: scale(0); /* mise à l'échelle à 0 */
}

/* Aspect si "cochée" */
input[type="checkbox"]:checked + label:after {
    opacity: 1; /* coche opaque */
    transform: scale(1); /* mise à l'échelle 1:1 */
}

input[type="checkbox"]:checked + label:before {
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    transform: rotate(125deg) translateY(-7px) translateX(1px);
    width: 13px;
    height: 8px;
}

/* aspect désactivée */
input[type="checkbox"]:disabled:not(:checked) + label:before,
input[type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: $color-charcoal;
    background-color: transparent;
}
/* styles de la coche (si cochée/désactivée) */
input[type="checkbox"]:disabled:checked + label:after {
    color: $color-black-a40;
}
/* on style aussi le label quand désactivé */
input[type="checkbox"]:disabled + label {
    color: $color-black-a40;
}

// Radios buttons

input[type="radio"]:not(:checked),
input[type="radio"]:checked {
    position: absolute;
    left: -9999px;
}

input[type="radio"]:checked + label:before {
    border: 1px solid $color-charcoal;
    border-radius: 100%;
}

input[type="radio"]:checked + label {
    font-weight: 700;
}

/* Label */
input[type="radio"]:not(:checked) + label,
input[type="radio"]:checked + label {
    position: relative;
    padding-left: 46px !important;
    cursor: pointer;
    font-weight: 700 !important;
}
input[type="radio"]:checked + label {
    font-weight: 700!important;
}

/* Aspect des radios */
/* :before sert à créer la case à cocher */
input[type="radio"]:not(:checked) + label:before,
input[type="radio"]:checked + label:before {
    content: '';
    position: absolute;
    z-index: 10;
    left:0;
    top: 0;
    width: 22px;
    height: 22px;
    background: transparent;
    border: 1px solid $color-charcoal;
    border-radius: 100%;
}

/* Aspect général de la coche */
input[type="radio"]:not(:checked) + label:after,
input[type="radio"]:checked + label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    height: 14px;
    width: 14px;
    color: #fff;
    border-radius: 100%;
    background-color: $color-charcoal;
    @include classic-transition; /* on prévoit une animation */
}
/* Aspect si "pas cochée" */
input[type="radio"]:not(:checked) + label:after {
    opacity: 0; /* coche invisible */
    transform: scale(0); /* mise à l'échelle à 0 */
}

/* Aspect si "cochée" */
input[type="radio"]:checked + label:before {
    border-color: $color-orange;
}
input[type="radio"]:checked + label:after {
    opacity: 1; /* coche opaque */
    transform: scale(1); /* mise à l'échelle 1:1 */
    background-color: $color-orange;
}

/* aspect désactivée */
input[type="radio"]:disabled:not(:checked) + label:before,
input[type="radio"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: $color-black-a40;
    background-color: transparent;
}
/* styles de la coche (si cochée/désactivée) */
input[type="radio"]:disabled:checked + label:after {
    color: #999;
}
/* on style aussi le label quand désactivé */
input[type="radio"]:disabled + label {
    color: #aaa;
}

/* aspect au focus de l'élément */
input[type="radio"]:checked:focus + label:before,
input[type="radio"]:not(:checked):focus + label:before {
    border: 1px solid $color-orange;
}

.Form-submit {
    max-width: 250px;
    margin: 10px 0;
}

.Form-btn--right {
    text-align: right;
}

/* Password indication elements */
.Form-passwordIndication {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: 0;
    width: 100%;
    @media($mq-from-tablet) {
        width: 350px;
    }
}

.Form-passwordIndication--valid {
    .Form-pwMessage {
        color: $color-valid;
    }

    .Form-pwBar:after {
        background-color: $color-valid;
        width: 100%;
    }
}

.Form-passwordIndication--medium {
    .Form-pwMessage {
        color: $color-warning;
    }

    .Form-pwBar:after {
        background-color: $color-warning;
        width: 50%;
    }
}

.Form-passwordIndication--low {
    .Form-pwMessage {
        color: $color-orange;
    }

    .Form-pwBar:after {
        background-color: $color-orange;
        width: 10%;
    }
}

.Form-pwBar {
    display: inline;
    width: 50px;
    height: 15px;
    border: 1px solid $color-border;
    border-radius: 2px;
    background-color: $color-white;

    &:after {
        content: '';
        display: block;
        height: 100%;
    }
}

.Form-pwMessage {
    font-weight: 900;
    font-size: 0.65rem;
}
