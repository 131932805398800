@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/gilroy-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/gilroy-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/gilroy-semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/gilroy-extrabold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/gilroy-bold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
