
.Richtext {
  p {
    margin-bottom: 10px;
  }

  a {
    color: $color-orange;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    margin-bottom: 15px;
  }
  li {
    list-style-type: initial;
  }
}
