/*! Header Component */

.Header {
  position: relative;
  z-index: $zindex-header; // 1000 - Override of mmenu.js
  padding: 10px 0;
  @media print {
    display: none;
  }


  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    z-index: 1002;
  }
}

.Header-nav {
  position: relative;
  background-color: $color-white;

  @media($mq-desktop) {
    width: 100%;
  }
}

.Header-uiList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Header-home {
  position: relative;
  display: block;
  text-decoration: none;
  min-height: 40px;
  width: auto;
}

.Header-logoContainer {
  position: relative;
  z-index: 500;
  @media($mq-from-tablet) {
    padding: 31px 0;
  }
  &:hover,
  &:focus {
    background-color: transparent;
  }
}
