/*! PrimaryButton */

.PrimaryButton {
    display: inline-block;
    border-radius: 0;
    padding: 8px 12px;
    width: 100%;
    margin: auto;
    text-align: center;
    text-decoration: none;
    font-size: 0.8rem;
    line-height: 1;
    font-weight: 600;
    color: $color-white;
    background-color: $color-orange;
    cursor: pointer;
    text-transform: uppercase;

    @media ($mq-from-tablet) {
        width: auto;
        margin: 0;
        font-size: 0.8rem;
    }

    &:hover,
    &:focus {
      background-color: $color-orange-darker;
    }

    &[disabled] {
      background-color: $color-black-a40;
      color: $color-white;
      cursor: initial;
      border-color: transparent;
      &:hover,
      &:focus {
        background-color: $color-black-a40;
        border-color: transparent;
      }
    }

    &[aria-hidden=true]{
        visibility: hidden;
        opacity: 0;
    }

    &[aria-hidden=false]{
        visibility: initial;
        opacity: 1;
    }
}

.PrimaryButton--thin {
    padding: 8px 45px;
}

// Variant: full width
.PrimaryButton--full {
    width: 100%;
}

// Variant with lined border and transparent background
.PrimaryButton--transparent {
    background-color: transparent;
    border-color: $color-black;
    color: $color-black;
    &:hover,
    &.focus-visible {
        color: $color-orange;
        background-color: $color-white;
        border-color: $color-orange;
    }
}
.PrimaryButton--light {
    background-color: transparent;
    border-color: $color-white;
    color: $color-white;

    &:hover,
    &.focus-visible {
        color: $color-orange;
        background-color: $color-white;
        border-color: transparent;
    }
}

.PrimaryButton--smaller {
    padding: 10px 35px;
    border-width: 2px;
    font-weight: 500;
    font-size: 0.8rem;
}

.PrimaryButton--smallest {
    padding: 10px 12px 10px 16px;
    max-width: 250px;
    font-size: 0.6rem;
}

/* Force uppercase attribute on some buttons -- utility */
.PrimaryButton--uppercase {
    text-transform: uppercase;
    font-weight: 700;
}
