/*! Popover SCSS component */
// Used in forms to give indications and explain fields to user.
// used as absolute element to be wrapped in a relative element.

.Popover {
  position: fixed;
  z-index: 200;
  bottom: 0;
  left: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 28px 35px;
  border-radius: 3px;
  width: 100%;
  background-color: $color-white;
  color: $color-white;
  @include outer-shadow;
  transition: opacity $transition-time-popin ease-in-out;
  pointer-events: none;
  @media($mq-desktop) {
    border: 2px solid $color-border;
    position: absolute;
    transform: translateX(50%);
    min-width: 210px;
    right: 50%;
    bottom: calc(100% + 20px);// calc(full element + arrow down size/spacing)
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -12px;// border size
    background-color: $color-white;
    border-top: 13px solid $color-white;
    width: 0;
    height: 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-left: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    background-color: $color-white;
    bottom: -9px;
    transform: rotate(-45deg);
  }

  &[data-popover] {
    opacity: 0;
    pointer-events: none;
    @media($mq-desktop) {
      width: 300px;
    }
  }

  &[data-popover]:focus,
  &[data-popover]:hover,
  &:focus, &:focus-within
  {
    opacity: 1;
    pointer-events: initial;
  }
}

.Popover--right {
  opacity: 0;
  @media($mq-desktop) {
    bottom: 30%;
    left: calc(100% + 20px);
    transform: translateY(50%);
    &:before {
      transform: translateX(50%) rotate(42deg);
    }
    &:after {
      transform: translate(50%, 50%) rotate(41deg);
      bottom: 50%;
      left: -16px;
    }
  }
}

.Popover--bottom {
  top: initial;
  bottom: 0;
  min-width: 350px;
  padding: 28px;
  @media($mq-desktop) {
    top: calc(100% + 30px);
    left: -50%;
    transform: translateX(50%);
    bottom: initial;
  }
  &:after {
    bottom: 50%;
    left: 50%;
    top: -9px;
    transform: translateX(50%) rotate(133deg);
  }
}

.Popover-title {
  color: $color-black;
  font-size: 0.9rem;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 10px;
}

.Popover-text {
  margin-bottom: 10px;
  color: $color-black !important;
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1.43;

  & + .PrimaryButton {
    margin-top: 15px;
    padding: 10px 40px;
  }
    
  p, a {
    color: $color-black;
  }
}

.Popover-text--lighter {
  font-weight: 400;
  line-height: 1.25;
}

.Popover-text--inline {
  display: flex;
  align-items: center;

}

.Popover-list {
  position: relative;
  z-index: 10;
  margin: 0;
}

.Popover-item {
  color: $color-black;
  font-size: 0.7rem;
  line-height: 1.2;
  &:before {
    content: '- ';
  }
}

.Popover-item--valid {
  color: $color-valid;
}
