/*! SectionHead Component */
// used to show titles

.SectionHead {
  margin: 45px 0;
}

.SectionHead--nomobile {
  display: none;
  @media($mq-desktop) {
    display: block;
  }
}

.SectionHead-title {
  @include thermor-title2c;
  text-align:center;
}

.SectionHead-mainTitle {
  margin-bottom: 20px;
  color: $color-charcoal;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  @media($mq-desktop) {
    font-size: 2.2rem;
  }
}

.Layout-inner {
  .SectionHead {
    margin: 0 0 18px 0;
    @media($mq-desktop) {
      margin: 0 0 45px 0;
    }
  }

  .Breadcrumbs + .SectionHead {
    @media($mq-desktop) {
      margin: 50px 0 45px 0;
    }
    margin: 25px 0 15px 0;
  }
}

.SectionHead-description {
  font-size: 0.9rem;
  font-weight: 400;
  color: $color-gray-2;
  text-align: left;
  margin-bottom: 40px;
}
