/*! Layout Component */

.Wrapper {
    position: relative;
    z-index: 1;
}

//  ## Layout Container
.Layout {
}
// Container that restricts the content width and horizontally centers
// Comes with a Layout-inner--narrow variant which uses a smaller max-width
.Layout-inner {
    @include container-max-width;
}
.Layout-inner:first-of-type {
    padding-top: 20px;
}

.Layout-inner:last-of-type {
   padding-bottom: 50px;
}

.Layout:last-of-type {
    padding: 30px 0 30px 0;
    @media ($mq-from-tablet) {
        padding: 22px 0 80px 0;
    }
}

.Layout--gray {
    background-color: $color-gray-4;
}

.Layout--bg-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  @media($mq-desktop) {
    background-image: url('../assets/images/login_bg.jpg');
  }
}

.Layout--bg-login:first-of-type {
  @media($mq-desktop) {
    padding: 0;
  }
}

.Layout--bg {
  position: relative;
  z-index: 1;
  background-color: #596D78;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
  @media($mq-desktop) {
    min-height: calc(100vh - (#{$full-header-height-desktop} + #{$prefooter-height-desktop} + #{$footer-height-desktop}))
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to left, #2c2e34, rgba(44, 46, 52, 0));
  }
}

.Layout--bg-login {
  .Form-mainTitle,
  .Form-subtitle{
    color: #FFF;
  }
}

.Layout--bg-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Layout-inner--narrow {
    margin-left: auto;
    margin-right: auto;
    @media ($mq-tablet) {
        max-width: $max-content-width-tablet * 0.8;
    }
    @media ($mq-desktop) {
        max-width: $max-content-width-desktop * 0.75;
    }
}

.Layout-inner--narrow {
  @media ($mq-desktop) {
    max-width: $max-content-width-desktop * 0.75;
  }
}


.Layout-inner--2col {
    @media ($mq-desktop) {
        display: flex;
        align-items: flex-start;
    }
}

.Layout-inner--right {
  @media ($mq-desktop) {
    width: 100%;
  }
}

.Layout-inner--right:first-of-type {
  @media ($mq-desktop) {
    padding: 0;
  }
}

.Layout-error {
  position: relative;
  z-index: 2;
  @media($mq-desktop) {
    margin-left: auto;
    margin-right: 0;
  }

  .Layout-text {
    color: #fff;
  }

  .Layout-link {
    position: relative;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    transition: all .2s ease-in-out;

    &:after {
      content: "";
      width: 100%;
      height: 2px;
      color: #fff;
      display: block;
      background-color: #fff;
      position: absolute;
      text-align: right;
      bottom: -5px;
      right: 0;
    }
  }
}

// Sidebar container
.Layout-sidebar {
  flex: none;
  width: 100%;
  @media($mq-desktop) {
    width: 28%;
    max-width: 300px;
    // Leaves 270px max for the sidebar nav
    padding-right: 30px;
  }
}

// Main content
.Layout-main {
    @media ($mq-desktop) {
        flex: 1 1 70%;
        // Make sure this container's height will be at least as tall as
        // the sidebar's height (if we want to use min-height:100% on its content)
        align-self: stretch;
    }
}

.Layout-results {
    margin: 20px 0;

    &[aria-hidden=true] {
        display: none;
    }
    &[aria-hidden=false] {
        display: block;
    }
}
