// ======
// Mixins
// ======

// Switch to grayscale font smoothing on OSX
@mixin osx-font-smoothing-grayscale {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin gradient-bg {
  background-image: linear-gradient(45deg,#ee6f61,#f59f42);
}

@mixin outer-shadow {
  box-shadow: 0 2px 18px 0 rgba(141, 141, 141, 0.5);
}

@mixin classic-transition {
  transition: all 0.2s ease-in-out;
}

@mixin outer-shadow-transition {
  box-shadow: 1px 2px 22px 0 transparent;
  transition: box-shadow 300ms ease-in-out;

  &:hover,
  &:focus {
    box-shadow: 1px 2px 22px 0 rgba(0, 0, 0, 0.5);
  }
}

@mixin list-style-check {
  list-style: none;
  &:before {
    content: ' ';
    background-image: url(asset('../icons/check.svg'));
    background-position: center;
    display: inline-block;
    flex: 0 0 28px;
    width: 28px;
    height: 28px;
    margin-right: 18px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 0.2rem;
    line-height: 1;
    background-repeat: no-repeat;
  }
}
