/*! HeroSideBlock Component */
// Contains a list of items with a bg image
// Default component is white text (light) -- variant is HeroSideBlock--variant-dark with dark text.

.HeroSideBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-color: grey; // default color
  background-position: right;
  width: 100%;
  min-height: 510px;
  padding: 15px;
  @media($mq-from-tablet) {
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 50%;
    padding: 15px 40px;
    background-position: center center;
  }
}

.HeroSideBlock--variant-smaller {
  min-height: 463px;
  background-image: url('../images/pro/benefices_cropped.png');
  background-position: 100%;
  @media($mq-desktop) {
    background-position: 58%;
  }
}

.HeroSideBlock--variant-shadow {
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.36);
}
