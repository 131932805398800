/* Main style
Used mainly to maintain footer on page bottom
 */

.main {
  min-height: calc(100vh - (#{$header-mobile-full-height} + #{$footer-mobile-height} + #{$prefooter-mobile-height}));
  @media($mq-desktop) {
    min-height: calc(100vh - (#{$header-desktop-full-height} + #{$footer-desktop-height} + #{$prefooter-desktop-height}));
  }
}
