.Select {
  position: relative;
}

.Select-label {
  display: flex;
  align-items: center;
  padding: 35px 40px;
  color: $color-charcoal;
  font-size: 16px;
}

.Select-icon {
  margin-left: 5px;
  transform: rotate(90deg);
  svg {
    color: $color-charcoal;
    font-size: 10px;
  }
}

.Select-list {
  display: none;
  position: absolute;
  top: 85%;
  left: 18px;
  flex-direction: column;
  background-color: white;
  border-top: 2px solid $color-charcoal;
}

.Select.show .Select-list {
  display: flex;
}

.Select-item {
  border-bottom: 1px solid $color-title;
  background-color: $color-field;
  color: #333333;
  font-size: 14px;
  font-weight: 400;
}

.Select-button {
  padding: 7px 26px;
}
