.Stepper {
  width: 100%;
  margin-bottom: 2rem;

}

.Stepper-steps {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media($mq-from-tablet) {
    flex-direction: row;
  }
}

.Stepper-item {
  flex: 1;

  & + .Stepper-item {
    margin: 15px 0 0 0;
    @media($mq-from-tablet) {
      margin: 0 0 0 30px;
    }
  }
}

.Stepper-step {
  position: relative;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 5px solid $color-border;
  text-align: left;
  transition: border-color 0.2s ease-in-out;

  &[aria-current=page] {
    border-color: $color-orange-light;
    .Stepper-subtitle,
    .Stepper-title {
      color: $color-black;
    }
  }

  &:focus {
    outline: none;
    .Popover[data-popover=stepper] {
      text-align: center;
      opacity: 1;
      pointer-events: initial;

      .Link {
        padding: 0;
      }
    }
  }
}

.Stepper-subtitle {
  color: $color-text-low-contrast;
  margin-bottom: 2px;
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 1.2;
}

.Stepper-title {
  color: $color-text-low-contrast;
  font-size: 0.9rem;
  line-height: 1.2;
}
